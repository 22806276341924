import * as Vue from 'vue/dist/vue.esm-bundler';

export const generateApplication = (component, identifier, injectVariables = [], customProps = {}, customPackages = [], customComponents = []) => {
    const element = document.querySelector(identifier)
    if (Boolean(element)) {
        const app = Vue.createApp(component, {...customProps, ...element.dataset })
        app.config.unwrapInjectedRef = true
        injectVariables.forEach(({ name, module }) => { app.provide(name, module); });
        customPackages.forEach(module => { app.use(module); });
        customComponents.forEach(({ name, module }) => { app.component(name, module); });
        app.mount(identifier)
    }
};