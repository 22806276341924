import App from '@components/map/App.vue';
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const axios = generateAxiosInstance();

generateApplication(App, "#app", [{
    name: '$axios',
    module: axios
}], {}, [VueSweetalert2])