<template>
  <div class="app" :key="windowWidth">
    <div class="app-container">
      <div class="app-header">
        <h2 class="app-header_title d-none d-lg-block px-5" :class="{'loading' : !displayMap}">Encuentra tu sucursal más cercana</h2>
      </div>
      <div class="app-wireframe" @click="highlightButton()" v-show="!displayMap">
        <button class="btn btn-outline-success start-button" ref="backDropFilter" type="button" @click.prevent="loadMap()">
          <img class="start-buton_image" :src="`https://tvaisucursales-16905.kxcdn.com/public-assets/white-location-pin`">
          Localiza tu sucursal
        </button>
      </div>
      <label class="panel-list_title mt-4" style="width: min-content;white-space:nowrap" :class="{'loading' : !displayMap}">Localiza una sucursal cerca de ti</label>
      <div class="app-panels_row mb-4">
        <div class="panel-list_input-container">
            <div class="panel-list_input-group" :class="{'loading' : !displayMap}">
              <button class="btn btn-outline-success panel-list_button reset" type="button" @click.prevent="requestGeolocationAccess()">
                <img class="btn-image"  :src="`https://tvaisucursales-16905.kxcdn.com/public-assets/target`">
              </button>
              <div id="geocoder"></div>
              <button class="btn btn-outline-success panel-list_button search" type="button" @click.prevent="dispatchSearchEvent()">
                <img class="btn-image" :src="`https://tvaisucursales-16905.kxcdn.com/public-assets/search-icon`">
              </button>
            </div>
        </div>
        <div class="map-container_header d-none d-lg-flex w-100">
          <div class="categories-container justify-content-between w-100" :class="{'loading' : !displayMap}">
            <ul class="list-group list-group-horizontal" >
              <li class="list-group-item category" :class="{'category-selected': filterByCategories.slugs.includes(category.slug)}" v-for="category in primaryCategories" :key="category" @click.prevent="selectCategory(category.slug)">
                <img class="category-image" :src="`https://tvaisucursales-16905.kxcdn.com/public-assets${category.image}`">
                <span>{{ category.title }}</span>
              </li>
              <li class="list-group-item category">
                <div class="dropdown">
                  <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    Otros
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li class="secondary-category"  v-for="category in secondaryCategories" :key="category" @click.prevent="selectCategory(category.slug)">
                      <img class="category-image" :src="`https://tvaisucursales-16905.kxcdn.com/public-assets${category.image}`">
                      <span>{{ category.title }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <button type="button" class="btn filter-button" @click.prevent="openFiltersPanel()">
              <i class="fas fa-sliders-h"></i>
              <label>Filtros ({{ filterByCategories.slugs.length }})</label>
            </button>
          </div>
        </div>
      </div>
      <div class="app-content">
        <div class="panel-list d-none d-lg-block h-100">
          <div class="panel-list_results-container h-100">
            <div class="panel-list_header">
              <label class="panel-list_results" :class="{'loading' : !displayMap}">{{ resultsText }}</label>
            </div>
            <div id="list-items-container" class="panel-list_items" v-if="screenWidth > 991">
              <div v-show="!displayMap" >
                <div v-for="n in 2" :class="{'loading' : !displayMap}" class="w-100 mb-3" style="aspect-ratio: 16/9"></div>
              </div>
              <ListItem
                v-for="location in locations"
                :class="{'loading' : !displayMap}"
                :key="location.id"
                :selectedLocation="selectedLocation"
                :location="location"
                :userLocationCoords="userLocationCoords"
                :freezedUserLocationCoords="freezedUserLocationCoords"
                @locationClicked="onLocationClicked"
              />
            </div>
          </div>
        </div>
        <div class="map-container">
          <div class="map-container_content">
            <div class="map" :class="{'loading' : !displayMap}" v-if="!displayMap"></div>
            <div id="map" class="map" v-else ></div>
          </div>
          <!-- Mobile list item view -->
          <div class="map-mobile_list d-sm-block d-md-block d-lg-none">
            <div class="mobile-container">
              <div class="mobile-results d-flex align-items-center" :class="{'loading' : !displayMap}">
                <button type="button" class="btn filter-button" @click.prevent="openFiltersPanel()">
                  <i class="fas fa-sliders-h"></i>
                  <label>Filtros ({{ filterByCategories.slugs.length }})</label>
                </button>
                <label class="panel-list_results mb-0">{{ resultsText }}</label>
              </div>
              <div id="list-items-container" class="mobile-locations" v-if="screenWidth < 992">
                <ListItem
                  v-for="location in locations"
                  :key="location.id"
                  :selectedLocation="selectedLocation"
                  :location="location"
                  :userLocationCoords="userLocationCoords"
                  :freezedUserLocationCoords="freezedUserLocationCoords"
                  :isMobileVersion="true"
                  @locationClicked="onLocationClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters panel -->
    <FiltersPanelVue
      :showPanel="showPanel"
      :categories="categories"
      :selectedCategories="selectedCategories"
      @applyClicked="onFiltersChange"
    />

    <LoadingModal v-if="status.isLoading"></LoadingModal>
  </div>
</template>

<script>
import FiltersPanelVue from './components/FiltersPanel.vue';
import ListItem from './components/ListItem.vue';
import mapMixin from '../../mixins/mapMixin';
import statsMixin from '../../mixins/statsMixin';
import LoadingModal from './LoadingModal.vue';

export default {
  inject: ['$axios'],
  mixins: [mapMixin,statsMixin],
  components: { ListItem, FiltersPanelVue, LoadingModal },
  data() {
    return {
      windowWidth: window.innerWidth, // Inicializamos con el ancho actual de la ventana
      map: null,
      userLocationMarker: null,
      userLocationCoords: null,
      freezedUserLocationCoords: [],
      locations: [],
      selectedLocation: null,
      selectedCategories: ['banco-azteca'],
      filterByCategories: {slugs: ['banco-azteca'], ids: []},
      categories: [
        {
          title: 'Banco Azteca',
          image: '/banco-azteca-nuevo',
          displayAsMain: true,
          hideAt: null,
          slug: 'banco-azteca'
        },
        {
          title: 'Cajeros Multiva',
          image: '/multiva',
          displayAsMain: true,
          hideAt: null,
          slug: 'multiva'
        },
        {
          title: 'Cajeros Mifel',
          image: '/banca-mifiel',
          displayAsMain: true,
          hideAt: 1087,
          slug: 'mifel'
        },
        {
          title: 'Cajeros Afirme',
          image: '/afirme',
          displayAsMain: true,
          hideAt: 1242,
          slug: 'afirme'
        },
        {
          title: 'Chedraui',
          image: '/chedraui',
          displayAsMain: true,
          hideAt: 1363,
          slug: 'corresponsal_chedraui'
        },
        {
          title: 'Telecomm',
          image: '/telecomm',
          displayAsMain: false,
          hideAt: null,
          slug: 'telecomm'
        },
        {
          title: 'Cajero Banco Bajio',
          image: '/banco-bajio',
          displayAsMain: false,
          hideAt: null,
          slug: 'banbajio'
        },
        {
          title: 'Canales de terceros',
          image: '/terceros-azteca',
          displayAsMain: false,
          hideAt: null,
          slug: 'canales-de-terceros'
        },
      ],
      showPanel: false,
      screenWidth: null,
      isPermissionGranted: false,
      status: {
        isLoading: false
      },
      displayMap: false
    }
  },
  created() {
    this.requestGeolocationAccess();
    window.addEventListener("resize", this.handleScreenChanges);
    this.screenWidth = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleScreenChanges);
  },
  async mounted() {
    // Generate uuid for current user
    this.generateUserIdentifier();
    // Escuchar el evento resize en el objeto window
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Asegurarse de limpiar el evento resize al desmontar el componente
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    displayMap: {
      handler(newVal) {
        if (newVal && newVal === true) {
          this.$nextTick(async () => {
            this.initializeMap();
            let geocoder = this.createGeocoder();
            let mobilegeocoder = this.createGeocoder();
            await this.map.on('load', () => {
              const searchInputHandler = (payload) => {
                if (payload?.result?.geometry?.coordinates) {
                  this.userLocationCoords = payload.result.geometry.coordinates;
                  this.navigateTo(this.userLocationCoords);
                  // Save stats
                  let search = payload?.result?.place_name;
                  let zipCode = payload?.result?.context?.length > 0 ? payload.result.context[0].text : null;
                  let locationCoords = payload?.result?.center ? payload.result.center : [];
                  this.saveStatistics('search_input', 
                  {
                    search: search, 
                    zip_code: zipCode, 
                    search_coordinates: locationCoords,
                  });
                }
              }
              geocoder.on('result', async (event) => searchInputHandler(event));
              mobilegeocoder.on('result', async (event) => searchInputHandler(event));
            });

            document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
            // document.getElementById('mobilegeocoder').appendChild(mobilegeocoder.onAdd(this.map));

            navigator.geolocation.watchPosition(() => {
              this.isPermissionGranted = true;
            });

            this.getLocations();
            this.navigateTo(this.userLocationCoords);
          });
        }
      },
      immediate: true
    },
    userLocationCoords: {
      async handler(newVal) {
        if (newVal && this.displayMap === true) {
          this.navigateTo(newVal);
          await this.getLocations();
        }
      },
      immediate: true,
    },
    locations: {
      handler(newVal) {
        if (newVal) {
          this.drawPinForLocations(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    filterByCategories: {
      async handler(newVal) {
        if (newVal && this.userLocationCoords) {
          await this.getLocations();
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    resultsText() {
      let text = 'No hay resultados cerca'
      if (this.locations && this.locations.length > 1) {
        text = `Hay ${this.locations.length} resultados cerca`
      } else if (this.locations && this.locations.length === 1) {
        text = `Hay ${this.locations.length} resultado cerca`
      }
      return text;
    },
    primaryCategories() {
      let categories = [];
      if (this.categories) {
        categories = this.categories.filter(c => c.displayAsMain === true && (this.screenWidth > c.hideAt));
      }
      return categories;
    },
    secondaryCategories() {
      let categories = [];
      if (this.categories) {
        categories = this.categories.filter(c => c.displayAsMain === false || (this.screenWidth < c.hideAt));
      }
      return categories;
    }
  },
  methods: {
    handleResize() {
      // Actualizar el ancho de la ventana
      this.windowWidth = window.innerWidth;
    },
    highlightButton(){
      this.$refs.backDropFilter.classList.add('highlight');

      // Después de un tiempo, quitar el resaltado
      setTimeout(() => {
        this.$refs.backDropFilter.classList.remove('highlight');
      }, 1000);
    },
    initializeMap() {
      // LOCAL: pk.eyJ1IjoiYWxvbnNvZ2RyMTkiLCJhIjoiY2wwbmJidmpuMDJhNDNpb2hhZ2F6NHJnaiJ9.UwAX5h3V26RzFe2dme0UEQ
      mapboxgl.accessToken = "pk.eyJ1IjoidHZhMiIsImEiOiJjbGtiZ2VmamIwZXlrM2NwaG9kM2NnZjJuIn0.fW8iGLOzACGcKHr61cdM9w";
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-99.14556, 19.41944], //CDMX by default
        zoom: 15,
      });

      this.map.on('load', (event) => {
        this.map.resize();
      });
    },
    createGeocoder() {
      let options = {
        countries: 'MX',
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Direccion, C.P o colonia',
        language: 'es-MX',
      }
      return new MapboxGeocoder(options)
    },
    navigateTo(coords) {
      // Fly effect to current coords
      this.map.flyTo({
        zoom: 15,
        center: coords
      });
      // Clear previous user location if exists
      if (this.userLocationMarker) {
        this.userLocationMarker.remove()
      }
      // Set user location pin according coords
      this.userLocationMarker = new mapboxgl.Marker()
      .setLngLat(coords)
      .addTo(this.map);
    },
    async getLocations() {
      const longitude = this.userLocationCoords[0];
      const latitude = this.userLocationCoords[1];
      const base64Filter = btoa(JSON.stringify(this.filterByCategories));
      await this.$axios.get(`/api/v1/locations?longitude=${longitude}&latitude=${latitude}&filter=${base64Filter}`)
      .then(response => {
        if (response?.data?.data) {
          this.locations = response.data.data;
        }
      })
      .finally(() => {
        this.status.isLoading = false;
      })
    },
    drawPinForLocations(locations) {
      // Clean previous drawed pins if exists
      const elements = document.getElementsByClassName("marker");
      while (elements.length > 0) elements[0].remove();
      // Build popup for pin
      locations.forEach((location, index) => {
        // Build HTML element for pin
        var el = document.createElement('div');
        el.index = index;
        el.id = `location-${location._id.$oid}`;
        el.className = `marker`;
        el.style.backgroundImage  = `url(https://tvaisucursales-16905.kxcdn.com/public-assets/${location.cali_pin_image})`;
        // Add click event listener to save stats
        el.addEventListener('click', (event) => {
          this.saveStatistics('pin_clicked',
            {
              sucursal_name: location.title,
              sucursal_coordinates: location.coordinates,
              sucursal_id: location.location_id
            }           
          );
          this.onLocationClicked(location);
        });
        // Create mapbox marker instance and add it to current map
        new mapboxgl.Marker(el)
        .setLngLat(location.coordinates)
        .addTo(this.map);
      });
    },
    requestGeolocationAccess() {
      // Check for browser compatibility with geolocation
      if (!('geolocation' in navigator)) {
        this.getUserLocationFromBackend();
      }
      // Ask for geolocation permission to user and use coords
      navigator.geolocation.getCurrentPosition(position => {
        const { longitude, latitude } = position.coords;
        this.userLocationCoords = [longitude,latitude]
        this.freezedUserLocationCoords = [longitude,latitude]
        this.isPermissionGranted = true;
        this.saveStatistics('geolocation_granted', null);
      }, err => {
        // If user deny geolocation permission, get location from backend
        this.getUserLocationFromBackend();
        this.saveStatistics('geolocation_granted', null);
      })
    },
    dispatchSearchEvent() {
      // Find mapbox geocoder input
      const inputs = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
      const input = inputs[0];
      var event = new KeyboardEvent('keydown', {
        bubbles: true, cancelable: true, keyCode: 13
      });
      input.dispatchEvent(event);
    },
    selectCategory(categorySlug) {
      this.filterByCategories.slugs = [categorySlug]
      // this.selectedCategories = [categorySlug];
    },
    onLocationClicked(location) {
      this.selectedLocation = location;
      // Center view in selected pin
      this.map.flyTo({
        zoom: 15,
        center: location.coordinates
      });
      // Scroll to current item in list
      // let locationDiv = document.getElementById(`${location._id.$oid}`);
      // locationDiv.scrollIntoView({
      //   behavior: 'auto',
      //   block: 'center',
      //   inline: 'center'
      // });
      let listContainer = document.getElementById('list-items-container');
      let locationDiv = document.getElementById(`${location._id.$oid}`);
      if (this.screenWidth < 992) {
        listContainer.scrollTo(locationDiv.offsetLeft - 10, 0);
      } else {
        listContainer.scrollTo(0, locationDiv.offsetTop - (listContainer.offsetHeight / 2))
      }
      
      // Change CSS for unselected pins and display as disabled effect
      for (let el of document.querySelectorAll('.marker')) el.style.filter = "grayscale(100%)";
      for (let el of document.querySelectorAll('.marker')) el.style.opacity = "0.5";
      // Change CSS for selected pin and focuss element in map
      let selectedPin = document.getElementById(`location-${location._id.$oid}`);
      selectedPin.style.filter = 'grayscale(0%)';
      selectedPin.style.opacity = '1';
    },
    openFiltersPanel() {
      this.showPanel = true;
    },
    onFiltersChange(payload) {
      this.filterByCategories = payload;
      this.showPanel = false;
    },
    getUserLocationFromBackend() {
      this.isGeolocationGranted = false;
      this.$axios.get(`/api/v1/locations/user_location`)
      .then(response => {
        if (response.data && response.data?.location.length > 0) {
          this.userLocationCoords = [response.data.location[1],response.data.location[0]]
          this.freezedUserLocationCoords = [response.data.location[1],response.data.location[0]]
        }
      })
      .finally(() => {
        this.status.isLoading = false;
      })
    },
    handleScreenChanges(data) {
      this.screenWidth = data.srcElement.innerWidth;
    },
    loadMap() {
      this.displayMap = true;
      // Save statistics
      this.saveStatistics('load_map_button_clicked', null);
    }
  }
}
</script>``

<style scoped lang="scss">
.app {
  display: flex;
  justify-content: center;
  padding: 1em 0em 0em 0em;
}
.app-header_mobile {
  width: 100%;
}
.app-container {
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 94%;
}
.app-header {
  display: flex;
  justify-content: center;
}
.app-header_title {
  font-size: 30px;
  font-weight: 700;
}
.app-content {
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: start;
}
.btn-image {
  height: 20px!important;
  width: 20px!important;
}
.panel-list {
  flex: 3;
  min-width: 390px;
}
.panel-list_title {
  color:#282828;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 1em;

}
.panel-list_input-container {
  flex: 1;
  min-width: clamp(260px, 90vw, 390px);
}
.map {
  width: 100%!important;
  height: 100%;
  max-height: 590px;
}
.map-container {
  display: flex;
  flex-direction: column;
  width: 100%!important;
}
.panel-list_button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
}
.search {
  background: #43b02a;
}
.start-button {
  background: #19a44d;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 285px;
  max-width: 80%;
  gap: 1em;
  color: white;
  top: 50%;
  position: absolute;
  height: 35px;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: width 2s height 2s background-color 2s;
}
.highlight{
  animation: highlight .2s ease-in-out;
}
@keyframes highlight{
  0%{
    background: #19a44d;
    border-color: #19a44d;
    left: 50%;
    top: 50%;
  }
  25%{
    background-color: rgb(181, 7, 7);
    border-color: rgb(181, 7, 7);
    left: 49%;
    top: 49%;
  }
  50%{
    background-color: rgb(181, 7, 7);
    border-color: rgb(181, 7, 7);
    left: 50%;
    top: 50%;
  }
  75%{
    background-color: rgb(181, 7, 7);
    border-color: rgb(181, 7, 7);
    left: 51%;
    top: 51%;
  }
  100%{
    background: #19a44d;
    border-color: #19a44d;
    left: 50%;
    top: 50%;
  }
}
.app-wireframe {
  display: flex;
  justify-content: center;
  align-content: center;
  &::after{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.311);
    backdrop-filter: blur(4px);
    z-index: 3;
  }
  button{
    position: absolute;
    z-index: 4;
  }
}
.app-wireframe_image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: auto;
  max-width: 1300px;
}
.app-wireframe_image_mobile {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100%;
}
.app-wireframe_image_tablet {
  height: 100vh;
  width: 100%;
}
.app-wireframe_image_tvs {
  width: 100%;
  height: auto;
  min-height: 700px;
}
.start-buton_image {
  width: 20px;
  height: auto;
}
.reset {
  /* background: #f3f3f3; */
  background: url("../images/buttonbg.png") no-repeat scroll 0 0 transparent;
}
.panel-list_results {
  color: #282828;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 1em;
}
.panel-list_input-group {
  align-content: center;
  display: flex;
}
#geocoder {
  flex-grow: 1;
}
#mobilegeocoder {
  flex-grow: 1;
}
.mapboxgl-ctrl-geocoder{
  min-width: 220px !important;
}
.mapboxgl-ctrl-geocoder--input {
  color: rgba(0,0,0,.75)!important;
  font-size: 15px!important;
}
.mapboxgl-ctrl-geocoder--button {
  border: none!important;
  background: transparent!important;
  top: -3px!important;
}
.panel-list_items {
  min-height: 100%;
  overflow-y: auto;
  padding: 0px 5px 0px 0px;
  height: 585px;
}
.header-title {
  font-weight: 600;
}
.category {
  align-items: center;
  border: none!important;
  border-right: #EAEAEA solid 1px !important;
  border-radius: 0px!important;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  gap: 5px;
  justify-content: center;
  height: 36px;
  padding: 2px 7px 2px 7px;
}
.category-image {
  height: 26px;
  width: 26px;
}
.category-selected {
  border-bottom: solid 3px #17a54d!important;
}
.secondary-category {
  display: flex;
  border-radius: 0px!important;
  align-content: center;
  gap: 5px;
  font-size: 13px;
  padding: 5px 10px;
}
.dropdown-menu {
  min-width: 170px!important;
}
.dropdown-toggle {
  font-size: 13px;
}
.mobile_input-group {
  display: flex;
  justify-content: space-between;
}
.mobile-locations {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  max-width: 852px;
}
.mobile-results {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0em 1em 0em;
}
.mobile-results > label {
  color: grey!important;
}
.filter-button {
  align-content: center;
  background: #43b02a;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 36px;
  min-width: 120px;
}
.filter-button > label {
  color: white;
  font-style: normal;
}
.mobile-container {
  display: flex;
  flex-direction: column!important;
  justify-content: space-around!important;
  flex-grow: 1;
  padding: 15px 0px 5px 0px;
}
.categories-container {
  display: flex;
  justify-content: space-between;
}
.map-container_header {
  flex: 8;
}
.map-container_content {
  flex: 8;
}
.map-mobile_list {
  flex: 1;
}
.map{
  aspect-ratio: 1/1;
  margin-top: 0;
}

@keyframes pulse{
  0% {
      background-color: #ffffff;
      color: transparent;
  }
  50%{
      background-color: rgb(225, 225, 225);
      color: transparent;
  }
  100%{
    background-color: #ffffff;
      color: transparent;
  }
}

.loading{
  color: transparent !important;
  border-radius: 5px !important;
  animation: pulse 1.5s ease-in-out infinite !important;
  border: none !important;
  img{
    display: none;
  }
  li, a, button, div, label{
    color: transparent !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    border: none !important;
    label{
      color: transparent;
    }
  }
}
.app-panels_row{
  display: flex;
  align-items: center;
  gap: 20px 30px;
}
</style>