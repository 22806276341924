<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <div class="col-4 offset-4 logo-container align-middle">
            <img src="https://tvaisucursales-16905.kxcdn.com/public-assets/bancoazteca" class="img img-fluid">
            <br>
            <div class="spinner-grow text-success test" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <br>
            <span>Cargando...</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'LoadingModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>


<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background-color: rgba(255,255,255);
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease
  }

  .logo-container{
      margin-top: 40vh !important;
      text-align: center;
  }
</style>